import { useQuery } from '@apollo/client';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot';
import React from 'react';
import NumberFormat from 'react-number-format';
import { DATA_QUERY } from '../../lib/queries';
import { Data } from '../../__generated__/Data';
import Loading from '../Loading';

type TooltipParams = {
  id: string;
  value: number | Date;
  color: string;
  size: number;
  data: {
    salary: string;
  };
}


const RosterSwarmPlot = () : JSX.Element => {

  const { loading, data } = useQuery<Data>(DATA_QUERY, {
    variables: {
      chart: 'roster_swarm',
    }
  })

  const getColors = (d: any) : any => {
    const value = data?.data.colors.find((color: { [x: string]: string }) => color[d.group] !== undefined);

    if(value) {
      return value[d.group];
    }
    else {
      return "#000";
    }    
  }

  if (loading) return <Loading />

  if (data) {
  
    return (
      <div className='w-[1200px] lg:w-full h-[400px]' data-testid='roster-swarm-plot'>
        <h2 className='text-xl font-bold'>Salary Distribution</h2>
        <ResponsiveSwarmPlot
          data={data.data.data}
          groups={data.data.keys}
          colors={getColors}
          value="contract"
          valueFormat="$.2f"
          valueScale={{ type: 'linear', min: 1, max: 13, reverse: false }}
          size={{
              key: 'salary',
              values: [
                  0,
                  25000000
              ],
              sizes: [
                  6,
                  20
              ]
          }}
          forceStrength={4}
          simulationIterations={100}
          borderColor={{
              from: 'color',
              modifiers: [
                  [
                      'darker',
                      0.6
                  ],
                  [
                      'opacity',
                      0.5
                  ]
              ]
          }}
          margin={{ top: 80, right: 100, bottom: 80, left: 100 }}
          axisLeft={{
              tickSize: 10,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Contract age',
              legendPosition: 'middle',
              legendOffset: -76
          }}
          tooltip={({ id, data } : TooltipParams) => (
              <div className='bg-white p-4 shadow'>{id} : <NumberFormat value={data.salary} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
          )}
        />
      </div>
    );
  }

  return <div></div>;
};

export default RosterSwarmPlot;