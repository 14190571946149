import React from 'react';

type Props = {
  children: React.ReactNode;
  testId?: string;
}

const Container = ({ children, testId }: Props) : JSX.Element => {
  return(
    <div className="px-4 sm:px-6 lg:px-8" data-testid={testId}>
      {children}
    </div>
  )
}

export default Container;