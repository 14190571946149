import { useQuery } from '@apollo/client';
import { ResponsiveRadar } from '@nivo/radar';
import React, { useEffect, useState } from 'react';
import { DATA_QUERY } from '../../lib/queries';
import { Data } from '../../__generated__/Data';
import Loading from '../Loading';

interface ChartDataPoints {
  [key: string]: number | string;
}

const SpendingByPosition = () : JSX.Element => {
  const [formattedData, setFormattedData] = useState<ChartDataPoints[]>([]);
  const [hiddenIds, setHiddenIds] = useState<(string|number)[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const firstRun = React.useRef(true);

  const { loading, data } = useQuery<Data>(DATA_QUERY, {
    variables: {
      chart: 'spending_by_position',
    }
  })

  const findColorByKey = (key: string) : any => {
    const value = data?.data.colors.find((color: { [x: string]: string }) => color[key] !== undefined);
    if(value) {
      return value;
    }
    else {
      const obj = {}
      obj[key] = '#000'
      return obj;
    }
  }

  useEffect(() => {
    if (!loading && data) {   
      if(firstRun.current) {
        setHiddenIds(data.data.keys);
        firstRun.current = false;
      }

      const tempData: ChartDataPoints[] = []
      const tempKeys: string[] = []
      const tempColors: string[] = []

      data.data.data.forEach((position:any) => {        
        const posData = {}        

        Object.keys(position).forEach((key:string, index:number) => {
          if (index === 0) {
            posData[key] = position[key]
          }
          else if (!hiddenIds.includes(key)) {
            posData[key] = position[key]
            if(!tempKeys.includes(key)) {
              tempKeys.push(key)
              tempColors.push(findColorByKey(key)[key])
            }
          }
        })

        tempData.push(posData)
      })

      setFormattedData(tempData)
      setKeys(tempKeys)
      setColors(tempColors)
    }
  }, [data, hiddenIds])

  if (loading) return <Loading />

  if (data) {
    return (
      <div data-testid='spending-by-position'>
        <h2 className='text-xl font-bold'>Spending by Position</h2>
        <div className='h-[380px]'>
        <ResponsiveRadar
            data={formattedData}
            keys={keys}
            indexBy="position"
            valueFormat="-$,.2f"
            margin={{ top: 60, right: 20, bottom: 80, left: 60 }}
            borderColor={{ from: 'color' }}
            gridLabelOffset={36}
            dotSize={10}            
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            colors={colors}
            blendMode="multiply"
            motionConfig="wobbly"
            legends={[
                {
                  anchor: 'top-left',
                  data: data.data.colors.map((keyIndex:any) => {
                    const key = Object.keys(keyIndex)[0]
                    return {
                      id: key,
                      label: key,
                      color: hiddenIds.includes(key) ? '#ccc' : findColorByKey(key)[key]
                    }
                  }),
                  direction: 'column',
                  onClick: (data) => {
                    if (hiddenIds.includes(data.id)) {
                      setHiddenIds(hiddenIds.filter((id) => id !== data.id))
                    }
                    else {
                      setHiddenIds([...hiddenIds, data.id])
                    }
                  },
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: '#999',
                  symbolSize: 12,
                  symbolShape: 'circle',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemTextColor: '#000'
                          }
                      }
                  ]
                }
            ]}
        />
        </div>
      </div>
    )
  }

  return <div></div>
}

export default SpendingByPosition