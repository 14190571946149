import React, { FC } from 'react';
import Container from '../components/layout/Container';
import HistoricalFinish from '../components/data/HistoricalFinish';
import SpendingByPosition from '../components/data/SpendingByPosition';
import TradesChart from '../components/data/TradesChart';
import RosterSwarmPlot from '../components/data/RosterSwarmPlot';

const Home : FC = () => {
  return (
    <Container testId='home-page'>
      <HistoricalFinish />
      <div className="flex flex-col lg:flex-row lg:space-x-4">
        <div style={{ height: 400 }} className='shadow-lg bg-white rounded-md p-6 mt-4 w-full lg:w-1/2 overflow-hidden'>
          <SpendingByPosition />
        </div>
        <div style={{ height: 400 }} className='shadow-lg bg-white rounded-md p-6 mt-4 w-full lg:w-1/2 overflow-hidden'>
          <TradesChart />
        </div>
      </div>
      <div className='shadow-lg bg-white rounded-md p-6 mt-4 overflow-scroll'>
        <RosterSwarmPlot />
      </div>
    </Container>
  )
}

export default Home


