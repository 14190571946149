import { useQuery } from '@apollo/client';
import { ResponsiveChord } from '@nivo/chord';
import React, { useEffect, useState } from 'react';
import { DATA_QUERY } from '../../lib/queries';
import { Data } from '../../__generated__/Data';
import Loading from '../Loading';

const TradesChart = () : JSX.Element => {
  const [colors, setColors] = useState<string[]>([]);

  const { loading, data } = useQuery<Data>(DATA_QUERY, {
    variables: {
      chart: 'trades',
    }
  })

  useEffect(() => {
    if (!loading && data) {   
      const tempColors: string[] = []

      data.data.colors.forEach((colorSet:any) => {

        Object.keys(colorSet).forEach((key:string, _index:number) => {
          tempColors.push(colorSet[key])
        })
      })

      setColors(tempColors)
    }
  }, [data])

  if (loading || !data) return <Loading />
  
  return (
    <div data-testid='trades-chart'>
      <h2 className='text-xl font-bold'>Trades</h2>
      <div className='h-[380px]'>
      <ResponsiveChord
        data={data.data.data}
        keys={data.data.keys}
        margin={{ top: 60, right: 60, bottom: 90, left: 60 }}
        valueFormat=".2f"
        padAngle={0.14}
        innerRadiusRatio={0.96}
        innerRadiusOffset={0.02}
        inactiveArcOpacity={0.25}
        arcBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ]
            ]
        }}
        activeRibbonOpacity={0.75}
        inactiveRibbonOpacity={0.25}
        ribbonBorderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.6
                ]
            ]
        }}
        labelRotation={-90}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1
                ]
            ]
        }}
        colors={colors}
        motionConfig="stiff"
      />
      </div>
    </div>
  )
}

export default TradesChart;