import { useQuery } from '@apollo/client';
import { ResponsiveBump } from '@nivo/bump';
import React from 'react';
import { DATA_QUERY } from '../../lib/queries';
import { Data } from '../../__generated__/Data';
import Loading from '../Loading';

const HistoricalFinish = () : JSX.Element => {
  const { loading, data } = useQuery<Data>(DATA_QUERY, {
    variables: {
      chart: 'historical_finish',
    }
  })

  const getColors = (d: any) : any => {
    const value = data?.data.colors.find((color: { [x: string]: string }) => color[d.id] !== undefined);

    if(value) {
      return value[d.id];
    }
    else {
      return "#000";
    }    
  }

  if (loading || !data) return <Loading />

  return (
    <div className='shadow-lg bg-white rounded-md p-6 overflow-scroll' data-testid='historical-finish'>
      <div className='w-[800px] lg:w-full h-[400px]'>
        <h2 className='text-xl font-bold'>Historical Finish</h2>
        <ResponsiveBump
          data={data.data.data}
          colors={getColors}
          lineWidth={3}
          activeLineWidth={6}
          inactiveLineWidth={3}
          inactiveOpacity={0.15}
          pointSize={10}
          activePointSize={16}
          inactivePointSize={0}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={3}
          activePointBorderWidth={3}
          pointBorderColor={{ from: 'serie.color' }}
          axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -36
          }}
          axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
          }}
          axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'ranking',
              legendPosition: 'middle',
              legendOffset: -40
          }}
          margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
          axisRight={null}
        />
      </div>
    </div>
  )
}

export default HistoricalFinish;