import React from 'react';
import {
  EllipsisHorizontalIcon
} from '@heroicons/react/24/outline'

const Loading = () : JSX.Element => (
  <div className="flex justify-center" data-testid='loading'>
    <div className="animate-pulse bg-white dark:bg-slate-800 p-2 w-10 h-10 ring-1 ring-slate-900/5 dark:ring-slate-200/20 shadow-lg rounded-full flex items-center justify-center inset-y-1/2 -translate-y-2/4">
      <EllipsisHorizontalIcon />
    </div>
  </div>
)

export default Loading;
